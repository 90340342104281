import React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../components/layout'
import Seo from '../components/seo'

const ProjectTemplate = ({ data }) => {
    const { frontmatter, html } = data.markdownRemark
    return (
        <Layout>
            <Seo title={frontmatter.title} description={frontmatter.subtitle} />

            <section className={"hero is-medium is-bold is-" + frontmatter.color}>
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">{frontmatter.title}</h1>
                        <h1 className="subtitle">{frontmatter.subtitle}</h1>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="columns is-reversed-mobile">
                        <div className="column is-two-thirds">
                            <div className="content">
                                <h1 className="title">Problem</h1>
                                <hr />
                                <p>{frontmatter.problem}</p>
                                <br />

                                <h1 className="title">Objectives</h1>
                                <hr />
                                {frontmatter.objectives.map(({ objective }) => (
                                    <p>{objective}</p>
                                ))}
                                <br />

                                <h1 className="title">How it works</h1>
                                <hr />
                                <p dangerouslySetInnerHTML={{ __html: html }}/>
                                <br />
                            </div>
                        </div>

                        <div className="column is-one-third">
                            <div className="content">
                                <h1 className="title">Tools</h1>
                                <hr />
                                <span className="tags are-medium">
                                    {frontmatter.tags.map((tag) => (
                                        <span className="tag is-dark">{tag}</span>
                                    ))}
                                </span>
                                <br />

                                <h1 className="title">See it in action</h1>
                                <hr />
                                <div>
                                    <a className={"button is-" + frontmatter.color} href={frontmatter.actionLink} target="_blank" rel="noreferrer">
                                        <span>{frontmatter.actionText}</span>
                                        <span className="icon">
                                            <FontAwesomeIcon icon={[frontmatter.iconCol, frontmatter.icon]} />
                                        </span>
                                    </a>
                                </div>
                                <br />

                                {frontmatter.blogPosts && (
                                    <>
                                        <h1 className="title">Related blog posts</h1>
                                        <hr />
                                        <div>
                                            {frontmatter.blogPosts.map(({ post }, i) => (
                                                <a key={i} className="button is-ghost" href={post.blogPostLink} target="_blank" rel="noreferrer">
                                                    <span className="icon">
                                                        <FontAwesomeIcon icon='newspaper' />
                                                    </span>
                                                    <span>{post.blogPostTitle}</span>
                                                </a>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ProjectTemplate

export const pageQuery = graphql`
    query($id: String) {
        markdownRemark(id: {eq: $id}) {
            html
            frontmatter {
                title
                subtitle
                color
                problem
                objectives {
                    objective
                }
                tags
                actionLink
                actionText
                iconCol
                icon
                blogPosts {
                    post {
                        blogPostLink
                        blogPostTitle
                    }
                }
            }
        }
    }
`